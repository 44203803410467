/* print.css - 출석부 인쇄용 스타일 */

@media print {
  @page {
    size: A4 landscape !important;
    margin: 10mm !important;
  }

  body {
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Malgun Gothic', sans-serif !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  /* 인쇄 시 모든 요소 숨기기 */
  body * {
    visibility: hidden !important;
  }

  /* 인쇄 컨텐츠만 표시 */
  .print-content, .print-content * {
    visibility: visible !important;
  }

  .print-content {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 297mm !important;
    max-width: 297mm !important;
  }

  /* 페이지 나누기 */
  .page-break {
    page-break-after: always !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  /* 출석부 / 요약표 공통 스타일 */
  .attendance-sheet, .summary-sheet {
    width: 100% !important;
    max-width: 297mm !important;
    margin: 0 auto !important;
    padding: 10px !important;
    box-sizing: border-box !important;
    position: relative !important;
    height: 190mm !important; /* A4 가로에서 여백을 제외한 높이 */
    display: flex !important;
    flex-direction: column !important;
  }

  /* 헤더 영역 */
  .header {
    width: 100% !important;
    padding-bottom: 10px !important;
    flex-shrink: 0 !important;
  }

  .title {
    font-size: 20px !important;
    font-weight: normal !important;
    text-align: center !important;
    margin-bottom: 5px !important;
  }

  .info-grid {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    gap: 10px !important;
    font-size: 10px !important;
    width: 100% !important;
    text-align: center !important;
  }

  .info-grid > div {
    padding: 2px !important;
  }

  /* 테이블 컨테이너 */
  .table-container {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: visible !important; /* 내용이 넘치지 않게 설정 */
    margin-bottom: 20px !important; /* 푸터를 위한 여백 */
    width: 100%  !important;
  }

  /* 테이블 기본 스타일 */
  .attendance-table, .summary-table {
    width: 100% !important;
    height: 100% !important;
    border-collapse: collapse !important;
    table-layout: fixed !important;
    border: 1px #333 !important;
    /*max-height: calc(190mm - 70px) !important; !* 푸터와 헤더를 위한 여백 제외 *!*/
  }

  .attendance-table th, .attendance-table td,
  .summary-table th, .summary-table td {
    border: 1px solid #333 !important;
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 10px !important;
    overflow: visible !important;
    padding: 1px !important;
    height: auto !important;
  }

  /* 헤더 셀 스타일 */
  .attendance-table th {
    background-color: #f3f4f6 !important;
    /*font-weight: bold !important;*/
  }

  /* 출석부 테이블 레이아웃 조정 */
  .number-col {
    writing-mode: vertical-rl !important;
    width: 30px !important;
    min-width: 30px !important;
  }

  .date-header, .approval-header, .name-header {
    width: 60px !important;
    min-width: 60px !important;
  }

  /* 날짜별 컬럼 너비 */
  .date-col {
    min-width: 15px !important;
    padding: 4px !important;
  }

  /* 교시 셀 */
  .period-col {
    width: 15px !important;
    min-width: 15px !important;
    font-size: 9px !important;
    padding: 1px !important;
  }

  /* 세로 텍스트 */
  .vertical-text {
    writing-mode: vertical-rl !important;
    text-orientation: mixed !important;
    width: 24px !important;
    height: 70px !important;
    white-space: nowrap !important;
  }

  /* 학생별 셀 스타일 */
  .number-cell {
    width: 30px !important;
    padding: 4px !important;
  }

  .name-cell {
    width: 60px !important;
    text-align: center !important;
  }

  .status-cell {
    width: 15px !important;
    padding: 0 !important;
    font-size: 10px !important;
  }

  /* 통계 영역 */
  .stat-cell {
    width: 24px !important;
    padding: 4px !important;
  }

  /* 페이지 번호 스타일 - 페이지 하단에 고정 */
  .page-number {
    position: absolute !important;
    bottom: 5px !important;
    left: 0 !important;
    right: 0 !important;
    display: flex !important;
    justify-content: center !important;
    font-size: 12px !important;
    padding: 2px !important;
    background-color: white !important; /* 배경색 추가 */
    z-index: 10 !important; /* 다른 요소 위에 표시 */
    height: 20px !important;
  }

  /* 요약 페이지 스타일 */
  .summary-table {
    width: 100% !important;
  }

  .summary-table th, .summary-table td {
    padding: 6px !important; /* 간격 줄임 */
    font-size: 11px !important; /* 글자 크기 조정 */
  }

  .summary-table th {
    background-color: #f9fafb !important;
  }

  /* 테이블 레이아웃 - 중요! */
  .attendance-table tr,
  .summary-table tr {
    display: table-row !important;
    height: auto !important; /* 자동 높이 */
  }

  /* 15명까지 표시할 수 있도록 각 행의 높이 조절 */
  /*.attendance-table tbody tr {
    height: 8.5mm !important; !* 고정 높이 설정 *!
    !*max-height: 8.5mm !important;*!
  }

  !* 더 많은 학생이 있을 경우 높이 자동 조절 *!
  .attendance-table.many-students tbody tr {
    height: auto !important;
    !*max-height: 7.5mm !important; !* 더 작은 높이로 설정 *!*!
  }
*/

  /* 테이블 본문이 가능한 많은 공간 차지하도록 설정 */
  .attendance-table tbody {
    height: 100% !important;
  }

  /* 행 높이를 자동으로 조정하여 가용 공간을 균등하게 분배 */
  .attendance-table tbody tr {
    height: auto !important; /* 자동 높이로 변경 */
  }

  /* 많은 학생 수에 대한 행 높이도 자동으로 조정 */
  .attendance-table.many-students tbody tr {
    height: auto !important;
  }

  .attendance-table th,
  .attendance-table td,
  .summary-table th,
  .summary-table td {
    display: table-cell !important;
  }

  /* 출석 상태 아이콘 (동그라미) */
  .status-icon {
    display: inline-block !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 50% !important;
    background-color: #000 !important;
  }

  /* 인쇄 영역의 크기 고정 */
  @page {
    size: 297mm 210mm !important;
  }
}